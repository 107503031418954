import React from "react";
import { Button } from "react-bootstrap";
import { File } from "react-feather";
import { toast } from "react-toastify";
import { ReactComponent as FilesIcon } from "../assets/icons/files.svg";

const UploadingToastMultiple = ({ data, onRetry, icon }) => {
  return (
    <div className="d-flex gap-2">
      {icon || <File />}
      <div className="flex-fill" style={{ maxHeight: '400px', overflowY: 'scroll' }}>
        <h3>Uploading, please wait...</h3>
        {data.items.map((item) => (
          <div
            key={item.index}
            className="flex-fill d-flex justify-content-between align-items-center mb-2"
          >
            {item.fail ? (
              <>
                <h4 className="mb-0">{item.name} failed</h4>
                <div>
                  <Button size="sm" onClick={() => onRetry(item.index)}>
                    Retry
                  </Button>
                </div>
              </>
            ) : (
              <>
                <FilesIcon width={20} height={20} />
                <h5 className="me-2" style={{ color: Math.round((item.progress || 0) * 100) === 100 ? 'green' : 'unset' }}>{item.name} ({Math.round((item.progress || 0) * 100)}%)</h5>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default class UploadProgressToast {
  toastId = "";
  /** @type {Array<Item>} */
  items = [];

  /**
   * @typedef {{ index: number; name: string; fail?: boolean; progress?: number }} Item
   * @param {{
   *  icon?: React.ReactNode;
   *  onRetry: (index: number) => void;
   *  items: Array<Item>;
   * }} props
   */
  constructor({ icon, onRetry, items }) {
    this.items = items;
    this.toastId = toast(
      <UploadingToastMultiple icon={icon} onRetry={onRetry} items={items} />,
      {
        progress: 0,
        progressStyle: { background: "#44AAA4" },
        autoClose: false,
        closeButton: false,
        closeOnClick: false,
        data: { items },
      }
    );
  }

  /** @param {import("react-toastify").UpdateOptions} [toastOptions] */
  updateToast(toastOptions = {}) {
    toast.update(this.toastId, {
      data: { items: [...this.items] },
      ...toastOptions,
    });
  }

  onUploadProgress(index = 0) {
    return ({ loaded, total }) => {
      this.items[index].progress = Math.round((100 * loaded) / total) / 100;

      if (loaded === total) {
        this.onUploadSuccess(index);
      }
      const progress =
        this.items.reduce((acc, item) => acc + (item.progress || 0), 0) /
        this.items.length;
      this.updateToast({ progress });
    };
  }

  onUploadFail(index = 0) {
    this.items[index].fail = true;

    this.updateToast();
  }

  onUploadSuccess(index) {
    this.items[index].success = true;
    this.items[index].fail = false;
    if (this.items.every((item) => item.success || item.fail)) {
      // this will be called when initial upload finished
      setTimeout(() => this.updateToast({ closeButton: true }), 2000);
    }

    if (this.items.every((item) => item.success)) {
      setTimeout(() => toast.dismiss(this.toastId), 4000);
    }
  }
}
