import {
  ArrowLeft,
  CheckCircle,
  Clock,
  User,
  BarChart2,
  Briefcase,
  Users,
  List,
  Book
} from "react-feather";

const adminSection = [
  {
    href: "/admin/customers",
    icon: Users,
    title: "Customers",
  },
  {
    href: "/admin/tasksboard",
    icon: List,
    title: "Tasks Board",
  },
  {
    href: "/admin/reports",
    icon: Book,
    title: "Reports",
  },
  {
    href: "/admin/changehistory",
    icon: Clock,
    title: "Change History",
  },
  {
    href: "/admin/snapshot",
    icon: List,
    title: "Snapshot",
  },
  {
    href: "/admin/valueType",
    icon: BarChart2,
    title: "Value types",
  },
  {
    href: "/admin/quoteTimeslots",
    icon: Clock,
    title: "Quote timeslots",
  },
  {
    href: "/admin/propertyType",
    icon: CheckCircle,
    title: "Property Types",
  },
  {
    href: "/admin/jobPurpose",
    icon: User,
    title: "Job Purposes",
  },
  {
    href: "/admin/jobAssignmentType",
    icon: Briefcase,
    title: "Job Assignment Types",
  },
  {
    href: "/admin/users",
    icon: Users,
    title: "Users",
  },
  {
    href: "/admin/inspectors",
    icon: Users,
    title: "Inspectors",
  },
  {
    href: "/admin/portfolio",
    icon: Briefcase,
    title: "Portfolios",
  },
  {
    href: "/admin/steps",
    icon: List,
    title: "Assignments Config",
  },
  {
    href: "/admin/ticket-status-types",
    icon: List,
    title: "Ticket Status Types",
  },
  {
    href: "/admin/unitType",
    icon: List,
    title: "Unit Type",
  },
  {
    href: "/admin/leaseType",
    icon: List,
    title: "Lease Type",
  },
  {
    href: "/admin/basementType",
    icon: List,
    title: "Basement Type",
  },
  {
    href: "/admin/bedroomsClassification",
    icon: List,
    title: "Bedrooms Classification",
  },
  {
    href: "/admin/lotShape",
    icon: List,
    title: "Lot Shape",
  },
  {
    href: "/admin/lotType",
    icon: List,
    title: "Lot Type",
  },
  {
    href: "/admin/rentRegulationStatus",
    icon: List,
    title: "Rent Regulation Status",
  },
  {
    href: "/admin/buildingClass",
    icon: List,
    title: "Building Class",
  },
  {
    href: "/admin/outdoorSpaceType",
    icon: List,
    title: "Outdoor Space Type",
  },
  {
    href: "/admin/floorLocation",
    icon: List,
    title: "Floor Location"
  },
  {
    href: "/admin/zone",
    icon: List,
    title: "Zoning District",
  },
  {
    href: "/admin/zoneOverlay",
    icon: List,
    title: "Zone Overlay",
  },
  {
    href: "/admin/compCondition",
    icon: List,
    title: "Comp Condition",
  },
  {
    href: "/admin/compType",
    icon: List,
    title: "Comp Type",
  },
  {
    href: "/admin/compKey",
    icon: List,
    title: "Comp Key",
  },
  {
    href: "/admin/inspectionQuestionForms",
    icon: List,
    title: "Inspection Questions Forms",
  },
  {
    href: "/",
    icon: ArrowLeft,
    title: "Back to Website",
  }
];

const navItems = [
  {
    title: "Admin Panel",
    pages: adminSection,
  },
];

export default navItems;
