import React, { useState, useEffect, useContext, useRef } from "react";
import { useParams, useNavigate, useLocation, useSearchParams } from "react-router-dom";

// reactstrap components and fontAwesome Icons;
import { Tab, Button, Card, Spinner, Badge, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlagCheckered, faFlag, faInfo } from "@fortawesome/free-solid-svg-icons";

//** Third Party Imports */
import axios from "axios";
import { toast } from "react-toastify";

//** Configs */
import { SERVER_URL } from "../../configs";

/** Custom Hooks */
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";

/** Dispatch Pointer; Services */
import { clearUserTasks, loadUserTasks } from "../../redux/actions/user";
import { loadTicketGroups } from "../../redux/actions/ticketGroups";
import { changeTicketStatus } from "../../services/ticket";
import { changeTicketStep } from "../../services/ticketStep";
import { reInitializeTeamsAndChannelsV2 } from "../../services/ticketGroup";

//** Components */
import ScheduledInspection from "./scheduledInspection";
import InspectionForm from "./inspectionForm"
import TicketStatusModal from "../../components/modal/ticketStatus";
import SubStep from "./substep";
import ConfirmationModal from "../../components/jobs/confirmationModal";
import Chat from "../chat/Chat";
import CustomNav, { CustomNavItem } from "../../components/CustomNav";
import ContentHeading from "./ContentHeading";
import TicketHistoryModal from "./TicketHistoryModal";
import StepsCustomNav from "./StepsCustomNav";

import PropertyTab from "./tabs/Property";
import CustomerTab from "./tabs/Customer";
import QuoteTab from "./tabs/Quote";
import FilesTab from "./tabs/Files";
import Finalization from "./finalization";
import AdditionalInfo from './tabs/AdditionalInfo';

import CustomerIcon from "./icons/Customer";
import FilesIcon from "./icons/Files";
import InspectionIcon from "./icons/Inspection";
import PropertyIcon from "./icons/Property";
import QuoteIcon from "./icons/QuoteIcon";


import useJobDetails from "./useJobDetails";
import useJobInspectionDetails from "./useJobInspectionDetails";
/** Context & Services */
import { UtilityContext } from "../../contexts/UtilityContext";
/** Styles */
import "./jobDetails.css";
import Revision from "./revision";
import { getFormattedInspectionDate } from "../../utils";

const JobDetails = () => {
    const {
        loadTicket,
        ticketInfo,
        handleUpdateIsPaid,
        handleUpdateAffiliateInfo,
        handleSendTicketGroupAffiliateEmail,
        ticketGroup,
        loadTicketGroup,
        loading,
        assignmentTypeStr,
        jobPurposeStr,
        propertySubTypeStr,
        propertyTypeStr,
        customerInfo,
        propertyInfo,
        generalInfoKey,
        setGeneralInfoKey,
        updateTicket,
        jobName,
    } = useJobDetails();
    const {
        scheduledInspection,
        scheduledInspections,
        setScheduledInspection,
        handleInspectionFormChange,
        inspectionForm,
        updateInspection,
        scheduleInspection,
        deleteScheduledInspection,
        editingInspection,
    } = useJobInspectionDetails();
    const { ticketGroupId, ticketId } = useParams()
    const [searchParams, setSearchParams] = useSearchParams();
    const activeTab = searchParams.get('activeTab') ?? generalInfoKey;
    const [activeKey, setActiveKey] = useState()

    const pageSize = useAppSelector(state => state.ticketGroups.pageSize)
    const filters = useAppSelector(state => state.search.filters)
    const usersMap = useAppSelector(state => state.appData.usersMap);

    const navigate = useNavigate()
    const location = useLocation()
    const { users, ticketStatusTypesMap } = useAppSelector(
        (state) => state.appData
    );
    const [loadingThreadMessages, setLoadingThreadMessages] = useState(false)
    const [mTicketStatusChangeDialog, setChangeTicketDialogStatus] = useState(false)
    const [stepsActiveKey, setStepsActiveTabKey] = useState("1")
    const [showTicketHistory, setShowTicketHistory] = useState(false);
    const [assignFinalizationModal, setAssignFinalizationModal] = useState(false);
    const [finalizationUser, setFinalizationUser] = useState("");
    const [activateNextStepModal, setActivateNextStepModal] = useState(0)
    const isStepActivated = useRef(false)
    const { filesCount } = useContext(UtilityContext);
    const [stepsColumnHeight, setStepsColumnHeight] = useState("76vh");
    const stepColumnRef = useRef();

    useEffect(() => {
        new ResizeObserver(() => {
            if (stepColumnRef.current?.offsetHeight) {
                setStepsColumnHeight(stepColumnRef.current.offsetHeight);
            }
        }).observe(stepColumnRef.current);
    }, [stepColumnRef.current?.offsetHeight]);

    useEffect(() => {
        if (activeTab) {
            setActiveKey(activeTab);
            setGeneralInfoKey(activeTab);
        }
    }, [activeTab])

    const setActiveKeyHandler = (tab) => {
        //console.log(tab);
        setActiveKey(tab);
        setGeneralInfoKey(tab);
        setSearchParams({ activeTab: tab });
    }

    // Chat state
    const [teamId, setTeamId] = useState(null);
    const [channelId, setChannelId] = useState(null);
    const chatRef = useRef();
    // end chat state

    const loadChatInfo = async (reset = false) => {
        if (reset) {
            setTeamId(null);
            setChannelId(null);
        }
        let teamIdResponse = await axios.get(`${SERVER_URL}/api/TicketGroup/GetMSTeamsTeamId`, { params: { ticketGroupId } });
        let channelIdResponse = await axios.get(`${SERVER_URL}/api/Ticket/GetMSTeamsChannelId`, { params: { ticketId } });

        const { teamId } = teamIdResponse.data;
        const { channelId } = channelIdResponse.data;
        // write a logic
        setTeamId(teamId);
        setChannelId(channelId);
    }

    useEffect(() => {
        loadChatInfo();
    }, [ticketGroupId, ticketId]);

    // Chat
    const reloadThreadList = () => {
        const go = async () => {
            await chatRef.current.reload();
            //await chatRef.current.select(id);
        }

        go();
    }

    const selectConversation = async (id) => {
        await chatRef.current.reload();
        await chatRef.current.select(id);
    }

    // end chat

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (ticketInfo.ticketSteps && !isStepActivated.current) {
            let _currentStep = ticketInfo.ticketSteps.find((elem) => !elem.completedDate && elem.assignedToId)
            let _allStepsCompleted = ticketInfo.ticketSteps?.every((elem) => elem.completedDate)
            setStepsActiveTabKey((_allStepsCompleted || _currentStep == null) ? "1" : _currentStep.ticketStepTypeId)

            isStepActivated.current = true
        }
    }, [ticketInfo.ticketSteps, isStepActivated])

    let currentStep = ticketInfo.ticketSteps?.find((elem) => !elem.completedDate && elem.assignedToId);
    let lastCompletedStep = ticketInfo.ticketSteps?.filter((elem) => { return elem.completedDate })
    if (lastCompletedStep) {
        lastCompletedStep = lastCompletedStep[lastCompletedStep.length - 1]
    }

    let allStepsCompleted = ticketInfo.ticketSteps?.every((elem) => elem.completedDate) && ticketInfo.ticketSteps.length > 0

    const handleTicketStepChange = async (userId, ticketStepStatus, cb) => {
        if (!allStepsCompleted) {
            let indexOf = ticketInfo.ticketSteps.findIndex((step) => step.ticketStepTypeId === ticketStepStatus)
            let _steps = ticketInfo.ticketSteps.slice(0, indexOf);
            let previousStepscompleted = _steps.every((elem) => elem.completedDate || elem.assignedToId)
            if (!previousStepscompleted) {
                if (!window.confirm('Are you sure you want to skip a step ?'))
                    return
            }
        } else {
            await changeTicketStatus({ ticketId: ticketInfo.id, ticketStatusTypeId: 2 });
        }

        if (ticketInfo.id) {
            try {
                await changeTicketStep({
                    ticketId: ticketInfo.id,
                    ticketStepTypeId: ticketStepStatus,
                    AssignedToId: userId,
                    assignmentNote: ""
                });
                toast.success("Ticket Step updated successfully !")
                loadTicket()
                loadTicketGroup()
                dispatch(clearUserTasks());
                dispatch(loadUserTasks());
                dispatch(loadTicketGroups(0, pageSize, filters))
                if (cb)
                    cb()
            } catch (err) {
                console.error(err)
                toast.error("Failed to update ticket step, please contact administrator !!!")
            }
        }
    }

    const getNextStepId = (id) => {
        let _step = ticketInfo.ticketSteps.find((step) => String(step.id) === String(Number(id) + 1))
        return _step?.ticketStepTypeId
    }

    const saveInspectionNotes = (value) => {
        axios.post(`${SERVER_URL}/api/Ticket/UpdateInspectionNotes`, {
            ticketId: ticketInfo.id,
            notes: value
        }).then(() => toast.success("Inspection memo saved successfully !"))
            .catch(() => toast.error("Failed to save inspection memo, please contact administrator !"))
    }

    const saveInspectionFormNotes = (inspectionId, value) => {
        axios.post(`${SERVER_URL}/api/Inspection/UpdateInspectionFormNotes`, {
            inspectionId: inspectionId,
            notes: value
        }).then(() => toast.success("Inspection note saved successfully !"))
            .catch(() => toast.error("Failed to save inspection note, please contact administrator !"))
    }

    const currentTicketStatus = ticketInfo.ticketStatuses?.length > 0 && ticketStatusTypesMap[ticketInfo.ticketStatuses[ticketInfo.ticketStatuses?.length - 1].ticketStatusTypeId];
    const ticketStatus = currentTicketStatus?.name ?? "";

    const handleTicketDetailsClose = (customTitle) => {
        const from = location.state?.from || "/";
        navigate(from, { state: { ticketId: ticketInfo.id, customTitle: customTitle } });
    }

    const handleAssignFinalization = async () => {
        setAssignFinalizationModal(false);
        await changeTicketStatus({ ticketId, assignedToUserId: finalizationUser, ticketStatusTypeId: 10 });
        loadTicket();
    };

    const copyTicketNameToClipboard = () => {
        navigator.clipboard.writeText(propertyInfo?.address ?? jobName);
    }

    const formatInspectionOption = (inspection) => {

        let inspector = null;
        if (inspection.inspectorId) {
            inspector = usersMap[inspection.inspectorId];
        }
        const date = getFormattedInspectionDate(inspection.date, {
            short: true,
        });

        return `${inspector ? inspector.firstName : 'N/A'} - ${date}`;
    }

    const fixTeams = async () => {
        let result = await reInitializeTeamsAndChannelsV2(ticketGroupId);
        console.log(result);
        await loadChatInfo();
    }

    return (
        <div className="jobDetails">
            <TicketHistoryModal
                show={showTicketHistory}
                onClose={() => setShowTicketHistory(false)}
                activeStep={stepsActiveKey}
                onActiveStepChange={setStepsActiveTabKey}
                ticketSteps={ticketInfo.ticketSteps}
                propertyId={propertyInfo.id}
            />
            <ConfirmationModal
                title="Assign finalization"
                show={assignFinalizationModal}
                setShow={() => setAssignFinalizationModal(false)}
                handleConfirm={handleAssignFinalization}
            >
                <h5>
                    Users
                    <Form.Select
                        name="userId"
                        value={finalizationUser}
                        onChange={(e) => setFinalizationUser(e.target.value)}
                    >
                        <option value="">Select User</option>
                        {users.filter(u => u.active).map((user, index) => (
                            <option key={index} value={user.id}>{`${user.firstName} ${user.lastName}`}</option>
                        ))}
                    </Form.Select>
                </h5>
            </ConfirmationModal>
            <TicketStatusModal
                ticket={ticketInfo}
                show={mTicketStatusChangeDialog}
                reloadTicket={loadTicket}
                setChangeTicketDialogStatus={setChangeTicketDialogStatus}
            />
            <div className="jobDetails_content">
                <ContentHeading
                    ticket={ticketInfo}
                    similarPropertiesCount={ticketInfo.property?.similarPropertiesCount}
                    placeId={ticketInfo.property?.googlePlaceId}
                    title={jobName}
                    copyTicketNameToClipboard={copyTicketNameToClipboard}
                    ticketStatus={ticketStatus}
                    status={
                        allStepsCompleted
                            ? "Completed"
                            : `Step ${currentStep ? currentStep.ticketStepTypeId : "Unassigned"}`
                    }
                    onClose={handleTicketDetailsClose}
                    onChangeStatus={() => setChangeTicketDialogStatus(true)}
                    editLink={`/dashboard/tickets/${ticketGroupId}/${ticketId}`}
                    showHistory={() => setShowTicketHistory(true)}
                    reloadTicket={loadTicket}
                />
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr",
                        gridAutoRows: "1fr",
                        columnGap: 16,
                    }}
                >
                    <div>
                        <Card className="rounded-lg" style={{ height: stepsColumnHeight }}>
                            <Card.Header className="d-flex justify-content-between">
                                <h4>General Info</h4>
                                <Button
                                    size="sm"
                                    variant="secondary"
                                    className="px-4 rounded"
                                    onClick={() => navigate(`/dashboard/tickets/${ticketGroupId}/${ticketId}`)}
                                >
                                    Edit
                                </Button>
                            </Card.Header>
                            {loading ? (
                                <div style={{ flex: 1 }} className="d-flex justify-content-center align-items-center">
                                    <Spinner animation="grow" />
                                </div>
                            ) : (
                                <div className="h-100 d-flex flex-row overflow-auto">
                                    <div style={{ width: 96, position: "sticky", top: 0, left: 0 }}>
                                        <CustomNav
                                            name="general-info"
                                            activeKey={activeKey}
                                            onActiveChange={setGeneralInfoKey}
                                            itemCommonProps={{
                                                size: "sm",
                                                style: { width: 72, height: 72 },
                                                className: "flex-column justify-content-evenly rounded-0 rounded-end",
                                            }}
                                        >
                                            <CustomNavItem eventKey="Property" onClick={() => setActiveKeyHandler("Property")}>
                                                <PropertyIcon /> Property
                                            </CustomNavItem>
                                            <CustomNavItem eventKey="Customer" onClick={() => setActiveKeyHandler("Customer")}>
                                                <CustomerIcon /> Customer
                                            </CustomNavItem>
                                            <CustomNavItem eventKey="Quote" onClick={() => setActiveKeyHandler("Quote")}>
                                                <QuoteIcon /> Quote
                                            </CustomNavItem>
                                            <CustomNavItem eventKey="Inspection" onClick={() => setActiveKeyHandler("Inspection")}>
                                                <InspectionIcon /> Inspection
                                            </CustomNavItem>
                                            <CustomNavItem eventKey="Files" onClick={() => setActiveKeyHandler("Files")}>
                                                <div className="position-relative">
                                                    <FilesIcon width={24} />
                                                    {!!filesCount && (
                                                        <Badge
                                                            pill
                                                            bg="danger"
                                                            style={{
                                                                top: -8,
                                                                position: "absolute",
                                                                width: 18,
                                                                height: 18,
                                                            }}
                                                        >
                                                            {filesCount}
                                                        </Badge>
                                                    )}
                                                </div>
                                                Files
                                            </CustomNavItem>
                                            <CustomNavItem eventKey="Finalization" onClick={() => setActiveKeyHandler("Finalization")}>
                                                <FontAwesomeIcon
                                                    icon={faFlagCheckered}
                                                    style={{ width: 18, height: 18 }}
                                                />{" "}
                                                Finalization
                                            </CustomNavItem>
                                            <CustomNavItem eventKey="Revision" onClick={() => setActiveKeyHandler("Revision")}>
                                                <FontAwesomeIcon
                                                    icon={faFlag}
                                                    style={{ width: 18, height: 18 }}
                                                />{" "}
                                                Revision
                                            </CustomNavItem>
                                            {/* <CustomNavItem eventKey="Additional_Info" onClick={() => setActiveKeyHandler("Additional_Info")}>
                                                <FontAwesomeIcon
                                                    icon={faInfo}
                                                    style={{ width: 18, height: 18 }}
                                                />{" "}
                                                Additional Info
                                            </CustomNavItem> */}
                                        </CustomNav>
                                    </div>
                                    <div className="w-100 px-2">
                                        <Tab.Container id="general-info-tabs" activeKey={activeKey} onSelect={setActiveKeyHandler}>
                                            <Tab.Content className="h-100">
                                                <Tab.Pane eventKey="Property" className="property-tab-pane h-100">
                                                    <PropertyTab
                                                        propertyInfo={propertyInfo}
                                                        ticketInfo={ticketInfo}
                                                        ticketGroup={ticketGroup}
                                                        propertyTypeStr={propertyTypeStr}
                                                        propertySubTypeStr={propertySubTypeStr}
                                                        jobPurposeStr={jobPurposeStr}
                                                        assignmentTypeStr={assignmentTypeStr}
                                                    />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="Customer" className="customer-tab-pane">
                                                  <CustomerTab customerInfo={customerInfo} affiliate={ticketGroup.affiliate} ticketGroup={ticketGroup} customerContactId={ticketGroup.customerContactId} />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="Quote" className="quote-tab-pane">
                                                    <QuoteTab />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="Inspection" className="inspection-tab-pane">

                                                    <Form.Group className="mb-2" disabled={editingInspection}>
                                                        <Form.Label>Inspections</Form.Label>
                                                        <Form.Select disabled={editingInspection} value={scheduledInspection?.id} onChange={e => setScheduledInspection(scheduledInspections.find(x => x.id == e.target.value) ?? {})}>
                                                            <option value={-1}>New inspection</option>
                                                            {scheduledInspections && scheduledInspections.map((x, y) => (<option key={y} value={x.id}>{formatInspectionOption(x)}</option>))}
                                                        </Form.Select>
                                                    </Form.Group>

                                                    {!!scheduledInspection.id ? (
                                                        editingInspection ? (
                                                            <InspectionForm
                                                                ticketId={ticketId}
                                                                btnLabel="Save"
                                                                inspectionForm={scheduledInspection}
                                                                handleSubmit={() =>
                                                                    updateInspection(scheduledInspection, true)
                                                                }
                                                                handleInspectionFormChange={handleInspectionFormChange}
                                                                saveInspectionNotes={saveInspectionNotes}
                                                                saveInspectionFormNotes={saveInspectionFormNotes}
                                                            />
                                                        ) : (
                                                            <ScheduledInspection
                                                                ticketId={ticketId}
                                                                isActiveTab={generalInfoKey === "Inspection"}
                                                                handleSubmit={updateInspection}
                                                                handleInspectionFormChange={handleInspectionFormChange}
                                                                deleteScheduledInspection={deleteScheduledInspection}
                                                            />
                                                        )
                                                    ) : (
                                                        <InspectionForm
                                                            ticketId={ticketId}
                                                            btnLabel="Schedule Inspection"
                                                            inspectionForm={inspectionForm}
                                                            handleSubmit={scheduleInspection}
                                                            handleInspectionFormChange={handleInspectionFormChange}
                                                            saveInspectionNotes={saveInspectionNotes}
                                                            saveInspectionFormNotes={saveInspectionFormNotes}
                                                        />
                                                    )}
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="Files" className="files-tab-pane h-100">
                                                    <FilesTab />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="Finalization" className="finalization-tab-pane" style={{ position: 'relative' }}>
                                                    <Finalization
                                                        ticketGroup={ticketGroup}
                                                        ticket={ticketInfo}
                                                        selectConversation={selectConversation}
                                                        ticketId={ticketInfo.id}
                                                        isPaid={ticketInfo.isPaid}
                                                        handleUpdateIsPaid={handleUpdateIsPaid}
                                                        handleUpdateAffiliateInfo={handleUpdateAffiliateInfo}
                                                        handleSendTicketGroupAffiliateEmail={handleSendTicketGroupAffiliateEmail}
                                                        loadTicket={loadTicket}
                                                    />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="Revision" className="finalization-tab-pane h-100">
                                                    <Revision
                                                        reloadThreadList={reloadThreadList}
                                                        selectConversation={selectConversation}
                                                        loadChatInfo={loadChatInfo}
                                                        ticket={ticketInfo}
                                                        loadTicket={loadTicket} />
                                                </Tab.Pane>
                                                {/* <Tab.Pane eventKey="Additional_Info" className="finalization-tab-pane h-100">
                                                    <AdditionalInfo
                                                        propertyInfo={propertyInfo}
                                                        ticketInfo={ticketInfo}
                                                        ticketGroup={ticketGroup}/>
                                                </Tab.Pane> */}
                                            </Tab.Content>
                                        </Tab.Container>
                                    </div>
                                </div>
                            )}
                        </Card>
                    </div>
                    <div>
                        <Card ref={stepColumnRef} className="rounded-lg" style={{ minHeight: "76vh" }}>
                            <Card.Header className="border-bottom border-light">
                                <h4>Steps</h4>
                            </Card.Header>
                            <div className="px-2 py-1 h-100 d-flex flex-column">
                                <StepsCustomNav
                                    ticketSteps={ticketInfo.ticketSteps}
                                    stepsActiveKey={stepsActiveKey}
                                    setStepsActiveTabKey={setStepsActiveTabKey}
                                    disabled={loadingThreadMessages}
                                />
                                <Tab.Container id="step-tabs" activeKey={stepsActiveKey}>
                                    {loading ? (
                                        <div
                                            style={{ flex: 1 }}
                                            className="d-flex justify-content-center align-items-center"
                                        >
                                            <Spinner animation="grow" />
                                        </div>
                                    ) : (
                                        <Tab.Content style={{ flex: 1 }}>
                                            {ticketInfo.ticketSteps?.map((step) => (
                                                <Tab.Pane
                                                    key={step.ticketStepTypeId}
                                                    eventKey={step.ticketStepTypeId}
                                                    className="h-100"
                                                >
                                                    <SubStep
                                                        reloadThreadList={reloadThreadList}
                                                        step={step}
                                                        getNextStepId={getNextStepId}
                                                        allStepsCompleted={allStepsCompleted}
                                                        updateTicket={updateTicket}
                                                        ticketInfo={ticketInfo}
                                                        currentStep={currentStep}
                                                        handleTicketStepChange={handleTicketStepChange}
                                                        setStepsActiveTabKey={setStepsActiveTabKey}
                                                        activateNextStepModal={activateNextStepModal}
                                                        setActivateNextStepModal={setActivateNextStepModal}
                                                        showAssignFinalizationModal={() =>
                                                            setAssignFinalizationModal(true)
                                                        }
                                                    />
                                                </Tab.Pane>
                                            ))}
                                        </Tab.Content>
                                    )}
                                </Tab.Container>
                            </div>
                        </Card>
                    </div>
                    <div>
                        <Card className="rounded-lg" style={{ height: stepsColumnHeight }}>
                            <Card.Header className="border-bottom border-light">
                                <h4 className="d-flex justify-content-between">
                                    Communication
                                    <button onClick={() => fixTeams()} className="btn btn-sm btn-light">Fix Teams</button>
                                </h4>
                            </Card.Header>
                            {(Object.keys(ticketGroup).length > 0 && (!teamId || !channelId)) && <>
                                <h4 className="text-center text-danger mt-2">Team or channel is not initialzed yet.</h4>
                            </>}
                            <div>
                                {teamId && channelId && (
                                    <Chat
                                        ref={chatRef}
                                        loadingMessages={loadingThreadMessages}
                                        setLoadingMessages={setLoadingThreadMessages}
                                        activeStep={stepsActiveKey}
                                        initSocket={true}
                                        teamId={teamId}
                                        channelId={channelId}
                                    />
                                )}
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default JobDetails;
