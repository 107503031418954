import React, { useContext, useEffect, useState, useRef } from "react";
import { Container, Navbar, Nav, Button, Spinner } from "react-bootstrap";
import Logo from "./assets/Logo";
import NavbarUser from "./NavbarUser";
import NavbarDropdown from "../navbar/NavbarDropdown";
import NavbarDropdownItem from "./NavbarDropdownItem";
import CustomSwitch from "../CustomSwitch";
import RouteTickets from "./RouteTickets";
import { AuthContext } from "../../contexts/JWTContext";
import { UtilityContext } from "../../contexts/UtilityContext";
import { LayoutContext } from "../../contexts/LayoutContext";
import { loadUserTasks } from "../../redux/actions/user";
import useAppSelector from "../../hooks/useAppSelector";
import useAppDispatch from "../../hooks/useAppDispatch";
import useOuterClick from "../../hooks/useOuterClick";
import { SERVER_URL } from "../../configs";

import "../../assets/scss/3-components/_calendar.scss";

import { toast } from "react-toastify";
import Icon from "./assets/list-check-solid";
import DropdownIcon from "./DropdownIcon";
import { useNavigate, useLocation, Link } from "react-router-dom";
import axios from "axios";
import { Menu } from "react-feather";


const LinkOrEmpty = (props) =>
  props.to ? (
    <Link {...props} />
  ) : (
    <React.Fragment>{props.children}</React.Fragment>
  );

const NavLink = ({ path, name, className, linkClassName = "" }) => {
  const location = useLocation();

  const isActive = location.pathname.toLowerCase().endsWith(path);

  return (
    <LinkOrEmpty
      to={path}
      className={`px-1 me-3 position-relative${
        isActive ? " border-bottom" : ""
      } ${linkClassName}`}
    >
      <Nav
        className={`py-2 ps-2 border-secondary text-light fw-normal${
          isActive ? " activeNav border-bottom border-3" : ""
        } ${className}`}
      >
        {name}
      </Nav>
    </LinkOrEmpty>
  );
};

const Header = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { showUnresolved, onUnresolvedChange } = useContext(UtilityContext);
  const { showNavbar, setShowNavbar } = useContext(LayoutContext);
  const dispatch = useAppDispatch();
  const { loading, tasks } = useAppSelector((state) => state.user);
  const usersMap = useAppSelector((state) => state.appData.usersMap);
  const navbarRef = useOuterClick(() => setShowNavbar(false));

  const resolveTask = (taskId) => {
    axios
      .post(`${SERVER_URL}/api/Task/ResolveTask?taskId=${taskId}`)
      .then(() => {
        dispatch(loadUserTasks());
        toast.success("Task resolved successfully !");
      })
      .catch((err) => {
        console.error(err);
        toast.error(
          "Failed to resolve the task, please contact administrator !"
        );
      });
  };

  const unresolveTask = (taskId) => {
    axios
      .post(`${SERVER_URL}/api/Task/UnresolveTask?taskId=${taskId}`)
      .then(() => {
        dispatch(loadUserTasks());
        toast.success("Task unresolved successfully !");
      })
      .catch((err) => {
        console.error(err);
        toast.error(
          "Failed to unresolve the task, please contact administrator !"
        );
      });
  };

  const handleUnresolvedChange = (e) => {
    onUnresolvedChange(e);
    dispatch(loadUserTasks());
  };
  useEffect(() => {
    if (window.innerWidth > 992) {
      setShowNavbar(false);
    }
  }, [window.innerWidth]);

  const dropdownRef = useRef(null);

  useEffect(() => {
      if (tasks.length > 0) {
          dropdownRef.current.bubbleDropdown();
      }
  }, [tasks]);

  return (
    <Navbar
      expand="lg"
      className="p-0 shadow-sm nav bg-primary"
      style={{ height: 70 }}
    >
      <Container fluid className="p-0 px-4 navContainer">
        <Navbar.Brand
          onClick={() => navigate("/")}
          className="nav-top-head p-0"
          style={{ cursor: "pointer" }}
        >
          <Logo />
        </Navbar.Brand>
        <Menu
          className="menuBar"
          onClick={() => setImmediate(() => setShowNavbar(!showNavbar))}
        />
        <Navbar.Collapse
          ref={navbarRef}
          id="basic-navbar-nav"
          style={{ display: showNavbar ? "block" : "none", zIndex: 999 }}
        >
          <Nav
            className="nav px-2 align"
            style={{ margin: "auto", display: "flex", alignItems: "center" }}
            onClick={() => setShowNavbar(false)}
          >
            <NavLink path="/" name="Jobs" />
            <NavLink path="/dashboard/event-view" name="Event view" />
            {window.innerWidth > 992 ? (
              <NavbarDropdown
                openOnHover
                dropdownMenuClassName="start-0"
                toggleClassName={`ps-0 ms-1${
                  window.location.pathname.includes("/calendar/") &&
                  window.location.pathname !== "/calendar/duedate"
                    ? " border-bottom"
                    : ""
                }`}
                Icon={() => (
                  <div
                    className="d-flex align-items-center"
                    style={{ cursor: "pointer" }}
                  >
                    <NavLink
                      className={
                        window.location.pathname.includes("/calendar/")
                          ? "activeNav border-0 ms-0"
                          : "ms-0"
                      }
                      name="Calendar"
                    />
                    <DropdownIcon />
                  </div>
                )}
                size="md"
                toggleAs="div"
              >
                <NavLink
                  className="text-dark"
                  linkClassName="ms-4"
                  path="/calendar/inspection"
                  name="Inspection Calendar"
                />
                <NavLink
                  className="text-dark dropdown-child"
                  linkClassName="ms-4"
                  path="/calendar/duedate-residential"
                  name="Res. Due Date calendar"
                />
                <NavLink
                  className="text-dark dropdown-child"
                  linkClassName="ms-4"
                  path="/calendar/internal-duedate-residential"
                  name="Internal Res. Due Date calendar"
                />
                <NavLink
                  className="text-dark dropdown-child"
                  linkClassName="ms-4"
                  path="/calendar/internal-duedate-commercial"
                  name="Internal Comm. Due Date calendar"
                />
              </NavbarDropdown>
            ) : (
              <>
                <NavLink
                  path="/calendar/inspection"
                  name="Inspection Calendar"
                />
                <NavLink
                  path="/calendar/duedate-residential"
                  name="Res. Due Date calendar"
                />
              </>
            )}

            <NavLink path="/calendar/duedate" name="Comm. Due Date calendar" />
            {window.innerWidth > 992 ? (
              <NavbarDropdown
                openOnHover
                dropdownMenuClassName="start-0"
                Icon={() => (
                  <div
                    className="d-flex align-items-center"
                    style={{ cursor: "pointer" }}
                  >
                    <NavLink name="Comps (Beta)" />
                    <DropdownIcon />
                  </div>
                )}
                size="sm"
                toggleAs="div"
              >
                <NavLink
                  path="/dashboard/create-comp"
                  name="New Comp"
                  linkClassName="ms-4"
                  className="text-dark dropdown-child"
                />
                <NavLink
                  path="/dashboard/comps"
                  name="Comps"
                  linkClassName="ms-4"
                  className="text-dark dropdown-child"
                />
              </NavbarDropdown>
            ) : (
              <>
                <NavLink path="/dashboard/create-comp" name="New Comp" />
                <NavLink path="/dashboard/comps" name="Comps" />
              </>
            )}
          </Nav>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="p-3">
              <Button
                className="px-4 py-2 round bg-white text-primary"
                size="lg"
                onClick={() => navigate("/dashboard/tickets")}
              >
                <span className="px-1">New Job</span>
              </Button>
            </div>
            <NavbarDropdown ref={dropdownRef}
              Icon={Icon}
              showBadge
              badgeClassName="bg-white text-dark"
              count={tasks.length}
            >
              <div>
                <div className="m-2">
                  <CustomSwitch
                    id="unresolved"
                    name="unresolved"
                    label="Show resolved"
                    checked={!showUnresolved}
                    onChange={handleUnresolvedChange}
                  />
                </div>
                {loading ? (
                  <div className="spinnerContainer mt-3">
                    <Spinner animation="grow" />
                  </div>
                ) : (
                  tasks.map((item, key) => (
                    <NavbarDropdownItem
                      key={key}
                      resolveTask={resolveTask}
                      unresolveTask={unresolveTask}
                      showUnresolved={showUnresolved}
                      usersMap={usersMap}
                      {...item}
                    />
                  ))
                )}
              </div>
            </NavbarDropdown>
            <RouteTickets />
            <NavbarUser user={authContext?.user} />
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
